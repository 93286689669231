import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  InputLabel,
  ListItemIcon,
  Menu,
  MenuItem,
  Switch,
  TextField,
  Typography,
  withStyles
} from "@material-ui/core";
import Xarrow from "react-xarrows";
import themeColors from "../../../assets/theme/colors";
import React, {useEffect, useState} from "react";
import {Add, Delete, Edit} from "@material-ui/icons";
import {graphQLApi} from "../../../services/GraphQLApi";
import {useAuthDispatch} from "../../../contexts/Auth";
import {makeStyles} from "@material-ui/core/styles";
import {useIntl} from "react-intl";
import {Editor} from "../../../components/Editor";
import ConfirmDialog from "../../../components/Dialogs/ConfirmDialog";
import {useNavigate, useParams} from "react-router-dom";


const RedMenuItem = withStyles((theme) => ({
  root: {
    color: theme.palette.error.main,
    '&:hover': {
      color: theme.palette.error.contrastText,
      backgroundColor: theme.palette.error.main,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.error.light,
      },
    },
    '&:focus': {
      color: theme.palette.error.contrastText,
      backgroundColor: theme.palette.error.main,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.error.light,
      },
    },
  },
}))(MenuItem);

const cardHeaderStyles = makeStyles(() => ({
  headerTitle: {
    fontSize: "0.825rem",
    fontWeight: "bold !important",
  },
  cardHeaderEvent: {
    padding: "0.15rem 1.25rem",
    cursor: "pointer",
    backgroundColor: themeColors.secondary.snackbar,
    color: themeColors.secondary.text,
  },
  cardHeaderAction: {
    padding: "0.15rem 1.25rem",
    cursor: "pointer",
    backgroundColor: themeColors.primary.snackbar,
    color: themeColors.primary.text,
  },
  cardContent: {
    padding: "0.25rem 1.25rem !important",
    fontSize: "0.95rem",
    color: "inherit",
    cursor: "pointer",
  }
}));

export default function WorkflowEdit() {
  const intl = useIntl();
  const navigate = useNavigate();
  const params = useParams();
  const [anchor, setAnchor] = useState(null);
  const [edit, setEdit] = useState(null);
  const [confirm, setConfirm] = useState(null);

  const classes = cardHeaderStyles();
  const [events, setEvents] = useState([]);
  const [actions, setActions] = useState([]);
  const [workflow, setWorkflow] = useState({
    id: null,
    event_id: null,
    schedule: '',
    schedule_title: '',
    is_active: false,
    columns: [],
  });

  const locales = {
    action: intl.formatMessage({id: "workflow.edit.action", defaultMessage: "action"}),
    event: intl.formatMessage({id: "workflow.edit.event", defaultMessage: "event"}),
  }

  const id = Number(params.id);

  const [clientError, setClientError] = useState();
  const handleClientErrors = (errors) => {
    if (Array.isArray(errors) && errors[0] && errors[0].hasOwnProperty('extensions') && errors[0].extensions.hasOwnProperty('validation')) {
      console.error('GraphQL API Error', errors[0].extensions.validation);
      setClientError(errors[0].extensions.validation);
    }
  };
  const client = new graphQLApi(useAuthDispatch(), null, {handleErrors: handleClientErrors});
  const actionFields = 'id type type_title description settings is_active event_id';
  const eventFields = 'id type type_title description filter_on_id filter_value filter_operand action_id filter_ids{id title}';
  useEffect(() => {
    let query = 'schedules: scheduleOptions{id title}\n' +
      'events: eventTypes{id title information parents settings{key type label default options{id title}}}\n' +
      'actions: actionTypes{id title information parents settings{key type label default options{id title}}}\n';
    if (id > 0) {
      query += 'workflows(filter:{id:' + id + '}){data{schedule event_id is_active actions{' + actionFields + '} events{' + eventFields + '}}}'
    }
    client.query('{' + query + '}').then(r => {
      if (r?.events) {
        setEvents(r.events);
      }
      if (r?.actions) {
        setActions(r.actions);
      }
      if (r?.workflows?.data) {
        let wf = r.workflows.data[0];
        wf.columns = [];
        let items = wf.events.filter(t => t.id === wf.event_id).map(i => ({
          ...i,
          workflow_id: id,
          item_type: 'event',
          schedule: wf.schedule,
          is_active: wf.is_active,
        }));

        while (items.length) {
          wf.columns.push(items);
          let type = wf.columns.length % 2 ? 'action' : 'event';
          let ids = items.map(i => i.id);
          items = wf[type + 's']
            .filter(i => ids.includes(i[type === 'action' ? 'event_id' : 'action_id']))
            .map(i => ({...i, workflow_id: id, item_type: type, settings: i.settings ? JSON.parse(i.settings) : null}));
        }
        setWorkflow(wf);
      } else {
        let event = {
          workflow_id: null,
          type: null,
          types: r.events,
          type_title: intl.formatMessage({
            id: "workflow.dialog.creation_title",
            defaultMessage: "Select the starting event for the workflow"
          }),
          item_type: 'event',
          schedule: '',
          is_active: true,
          filter_on_id: null,
          filter_value: null,
          filter_operand: 'no_filter',
        };
        setWorkflow({
          id: null,
          event_id: null,
          schedule: '',
          is_active: true,
          columns: [
            [event]
          ]
        });
        setEdit(event);
      }
    });
  }, []);

  const handleMenu = (event, item, column) => {
    setAnchor({target: event.currentTarget, item: item, column: column});
  }

  const editWorkflowItem = () => {
    setClientError(null);
    setEdit(anchor.item);
    setAnchor(null);
  }

  const addWorkflowItem = () => {
    setClientError(null);
    let newItem = {
      id: null,
      workflow_id: id,
      parent_type: anchor.item.type,
      type: '',
      types: (anchor.item.item_type === "action" ? events : actions).filter(i => !anchor.item.type || i.parents.includes(anchor.item.type)),
      item_type: anchor.item.item_type === "action" ? 'event' : 'action',
      type_title: intl.formatMessage({
        id: "workflow.edit.dialog.create_item",
        defaultMessage: "Create new {type} after {parent}"
      }, {
        type: locales[anchor.item.item_type === "action" ? 'event' : 'action'],
        parent: anchor.item.type_title,
      }),
      description: '',
    };
    if (anchor.item.item_type === "action") {
      newItem.action_id = anchor.item.id;
      newItem.filter_on_id = null;
      newItem.filter_value = null;
      newItem.filter_operand = 'no_filter';
    } else {
      newItem.event_id = anchor.item.id;
      newItem.is_active = true;
      newItem.settings = {};
    }
    setEdit(newItem);
    setAnchor(null);
  }

  const getValue = (key) => {
    let v = '';
    if (key.startsWith("settings.") && edit.settings) {
      v = edit.settings[key.substring(9)];
    } else {
      v = edit[key];
    }
    // console.log(key, key.substring(0,9), key.substring(9), v, edit.settings);
    if (v === undefined) return '';
    return v;
  };
  const setValue = (key, value) => {
    let e = {...edit};
    if (key === 'type') {
      let item = (e.item_type === "action" ? actions : events).find(i => i.id === value);
      e.settings = {};
      // console.log(e.item_type, value, item?.settings);
      item?.settings.forEach(s => {
        e.settings[s.key] = (s.options && s.options.length === 1) ? s.options[0].id : s.default;
        // console.log(s.key, e.settings[s.key]);
      });
    }
    if (key.substring(0, 9) === "settings.") {
      e.settings[key.substring(9)] = value;
    } else {
      e[key] = value;
    }
    setEdit(e);
  };

  const saveWorkflowItem = (editedData = edit) => {
    let vars = null;
    if (!editedData.workflow_id || editedData.type === "App\\Events\\ScheduledWorkflowEvent") {
      vars = {
        schedule: 'String',
        is_active: 'Boolean',
      };
      let data = {
        schedule: editedData.schedule,
        is_active: editedData.is_active,
      };
      if (editedData.workflow_id) {
        vars.id = 'ID!';
        vars.event_id = 'ID';
        data.id = editedData.workflow_id;
        data.event_id = editedData.id;
      }
      return client.mutation('workflow', vars, data, 'id event_id schedule is_active schedule_title').then(r => {
        if (!r?.response) return;
        r = r.response;
        let cols = [...workflow.columns];
        cols[0][0] = {
          ...cols[0][0],
          type: editedData.type,
          type_title: editedData.type_title,
          id: r.event_id,
          workflow_id: r.id,
          schedule: r.schedule,
          is_active: r.is_active,
          description: r.schedule_title,
        };
        setWorkflow({...workflow, ...r, columns: cols});
        if (!editedData.workflow_id) {
          editedData.id = r.event_id;
          editedData.workflow_id = r.id;
          if (editedData.type !== "App\\Events\\ScheduledWorkflowEvent") {
            saveWorkflowItem(editedData).then(() => {
              navigate('/admin/workflows/' + r.id, {replace: true});
            });
          } else {
            navigate('/admin/workflows/' + r.id, {replace: true});
          }
        } else {
          setEdit(null);
        }
      });
    }
    let data = {...editedData};
    if (editedData.item_type === "action") {
      vars = {
        workflow_id: 'ID',
        event_id: 'ID',
        type: 'String',
        settings: 'String',
        is_active: 'Boolean',
      };
      data.settings = JSON.stringify(editedData.settings);
    } else {
      vars = {
        workflow_id: 'ID',
        action_id: 'ID',
        type: 'String',
        filter_on_id: 'Int',
        filter_value: 'String',
        filter_operand: 'String',
      };
      if (editedData.settings?.filter_value) {
        data.filter_value = editedData.settings?.filter_value;
        data.filter_operand = '=';
      }
    }
    if (editedData.id) {
      vars.id = 'ID!';
    }
    return client.mutation(editedData.item_type, vars, data, editedData.item_type === "action" ? actionFields : eventFields).then(r => {
      if (r?.response) {
        r = r.response;
        let wf = {...workflow};
        if (editedData.id) {
          wf.columns = wf.columns.map(c => c.map(i => {
            if (i.id === editedData.id && i.type === editedData.type) {
              return {
                ...r,
                workflow_id: editedData.workflow_id,
                item_type: editedData.item_type,
                settings: typeof r.settings === "string" ? JSON.parse(r.settings) : null,
              };
            } else {
              return i;
            }
          }))
        } else {
          let cIdx = null;
          wf.columns.forEach((c, ci) => c.forEach(i => {
            if (i.id === data[editedData.item_type === "action" ? "event_id" : "action_id"] && i.type === editedData.parent_type) {
              cIdx = ci;
            }
          }))
          if (cIdx !== null) {
            if (!wf.columns[cIdx + 1]) wf.columns[cIdx + 1] = [];
            wf.columns[cIdx + 1].push({
              ...r,
              workflow_id: editedData.workflow_id,
              item_type: editedData.item_type,
              settings: typeof r.settings === "string" ? JSON.parse(r.settings) : null,
            });
          }
        }
        setWorkflow(wf);
        setEdit(null);
      }
    });
  }

  const findItemAndChildren = (item) => {
    let wf = {...workflow};
    let items = [];
    wf.columns.forEach(c => {
      c.forEach(i => {
        if (i.id === item.id && i.type === item.type) {
          items.push(i.item_type + ':' + i.id);
        }
        let parentId = i.item_type === "action" ? 'event:' + i.event_id : 'action:' + i.action_id;
        if (items.includes(parentId)) {
          items.push(i.item_type + ':' + i.id);
        }
      });
    });
    return items;
  }

  const deleteWorkflowItem = (confirmed) => {
    if (confirmed && anchor?.item?.id) {
      client.mutate('{' + (confirm.item_type) + 'Delete(id:' + confirm.id + ')}').then(r => {
        if (r) {
          let wf = {...workflow};
          let items = findItemAndChildren(confirm);
          wf.columns = wf.columns.map((c) => c.filter(i => !items.includes(i.item_type + ':' + i.id)));
          setWorkflow(wf);
          setAnchor(null);
        }
      });
    } else {
      setAnchor(null);
    }
    setConfirm(null);
  }

  return <Grid container style={{
    paddingTop: 16,
    display: "flex",
    flexFlow: "row",
    gap: 16,
  }}>{workflow?.columns?.map((column, cIdx) =>
    <Grid item style={{display: "flex", flexFlow: "column", gap: 16}} key={'column-' + cIdx}>
      {column.map(item => <Card
        style={{
          outline: ((anchor?.item || edit)?.id === item.id && (anchor?.item || edit)?.type === item.type) ? "2px solid " + themeColors.primary.light : "none",
          width: 300,
          color: item.is_active === false ? themeColors.gray[300] : themeColors.text.primary,
        }}
        key={item.item_type + '-' + item.id}
        id={item.item_type + "-" + item.id}
        onClick={(e) => handleMenu(e, item, cIdx)}
      >
        <CardHeader
          disableTypography
          classes={{
            root: item.item_type === "action" ? classes.cardHeaderAction : classes.cardHeaderEvent,
            content: classes.headerTitle
          }}
          title={locales[item.item_type].replace(/\b\w/g, s => s.toUpperCase())}
        />
        <CardContent classes={{root: classes.cardContent}}>
          <strong>{item.type_title}</strong><br/>
          {item.description?.replace(/(<([^>]+)>)/gi, '')}
        </CardContent>
      </Card>)}
    </Grid>)}
    {workflow?.columns?.slice(1).map((column) => column.map(item => <Xarrow
      key={'arrow-' + item.id}
      start={(item.item_type === 'action' ? 'event' : 'action') + "-" + item[item.item_type === 'action' ? 'event_id' : 'action_id']}
      end={(item.item_type === 'event' ? 'event' : 'action') + "-" + item.id}
      lineColor={themeColors.gray[300]}
      showHead={true}
      headColor={themeColors.gray[300]}
      strokeWidth={2}
    />))}
    {anchor && <Menu open={!!anchor} anchorEl={anchor.target} onClose={() => setAnchor(null)}>
      <MenuItem onClick={addWorkflowItem}><ListItemIcon><Add fontSize={"small"}/></ListItemIcon>{
        intl.formatMessage({
          id: "workflow.edit.item_menu.add_new_item",
          defaultMessage: "Add {new_type} after this {type}"
        }, {
          type: locales[anchor.item.item_type],
          new_type: locales[anchor.item.item_type === "action" ? "event" : "action"],
        })
      }</MenuItem>
      <MenuItem onClick={editWorkflowItem}><ListItemIcon><Edit fontSize={"small"}/></ListItemIcon>{
        intl.formatMessage({
          id: "workflow.edit.item_menu.edit_this",
          defaultMessage: "Edit this {type}"
        }, {type: locales[anchor.item.item_type]})
      }</MenuItem>
      {anchor.column > 0 &&
        <RedMenuItem onClick={() => setConfirm(anchor.item)}><ListItemIcon><Delete
          fontSize={"small"}/></ListItemIcon>{
          intl.formatMessage({
            id: "workflow.edit.item_menu.delete_from_this",
            defaultMessage: "Delete from this {type}"
          }, {
            type: locales[anchor.item.item_type],
            count: findItemAndChildren(anchor.item).length - 1,
          })
        }</RedMenuItem>}
    </Menu>}
    {edit && <Dialog open={true} onClose={() => setEdit(null)} maxWidth={"lg"}>
      <DialogTitle
        disableTypography><Typography variant={"subtitle1"}>{edit.id ? intl.formatMessage({
          id: "workflow.edit.dialog.title",
          defaultMessage: "Edit {type} \"{title}\""
        },
        {
          title: edit.type_title,
          type: locales[edit.item_type],
        }) : edit.type_title}</Typography></DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={8} style={{display: "flex", flexDirection: "column", gap: 16}}>
            <Typography variant={"subtitle2"} style={{fontWeight: "bold"}}>{intl.formatMessage({
              id: "workflow.edit.dialog.settings_heading",
              defaultMessage: "Settings for the {type}"
            }, {type: locales[edit.item_type]})}</Typography>
            {!edit.id &&
              <TextField
                error={clientError?.hasOwnProperty('type')}
                key={'settings_type'}
                id={'settings_type'}
                name={'settings_type'}
                fullWidth
                label={intl.formatMessage({
                  id: "workflow.edit.dialog.type_of_new_item",
                  defaultMessage: "Select the type of {type} you want to add"
                }, {
                  type: locales[edit.item_type],
                })}
                select
                value={getValue('type') || ''}
                onChange={e => setValue('type', e.target.value)}>
                {edit.types.map(f =>
                  <MenuItem key={'settings_type_' + f.id} value={f.id}>{f.title}</MenuItem>
                )}</TextField>}
            {(edit.item_type === "action" ? actions : events).find(i => i.id === edit.type)?.settings?.map(s =>
              s.type === 'select' ?
                <TextField
                  error={clientError?.hasOwnProperty(s.key)}
                  key={'settings_' + s.key}
                  id={'settings_' + s.key}
                  name={'settings_' + s.key}
                  fullWidth
                  label={s.label}
                  select
                  value={getValue(s.key) || ''}
                  onChange={e => setValue(s.key, e.target.value)}>
                  {s.options.map(f =>
                    <MenuItem key={'settings_' + s.key + '_' + f.id}
                              value={f.id}>{f.title}</MenuItem>
                  )}</TextField>
                : s.type === 'bool' ?
                  <FormControl
                    key={'settings_' + s.key}
                    error={clientError?.hasOwnProperty(s.key)}
                  ><FormGroup>
                    <FormLabel><FormControlLabel
                      label={s.label}
                      control={
                        <Switch
                          name={'settings_' + s.key}
                          id={'settings_' + s.key}
                          checked={Boolean(getValue(s.key))}
                          onChange={e => setValue(s.key, e.target.checked)}
                        />
                      }
                      style={{margin: "5px 0 5px 0"}}
                    /></FormLabel>
                  </FormGroup></FormControl>
                  : s.type === 'html' ?
                    <FormControl
                      error={clientError?.hasOwnProperty(s.key)}
                      id={'settings_' + s.key}
                      key={'settings_' + s.key}
                      // error={validation[field.field].length > 0}
                      fullWidth
                      style={{marginTop: 16}}
                    >
                      <InputLabel
                        style={{marginBottom: 10, marginTop: "-16px"}}
                        shrink={true}
                      >{s.label}</InputLabel>
                      <Editor
                        value={getValue(s.key)}
                        onBlur={newContent => setValue(s.key, newContent)}
                      />
                    </FormControl>
                    : s.type === 'static' ?
                      <Typography
                        variant={"body2"}
                        dangerouslySetInnerHTML={{
                          __html: edit.id ? edit.description : s.label
                        }}/>
                      : <TextField
                        error={clientError?.hasOwnProperty(s.key)}
                        id={'settings_' + s.key}
                        key={'settings_' + s.key}
                        name={'settings_' + s.key}
                        fullWidth
                        type={s.type}
                        label={s.label}
                        value={getValue(s.key)}
                        onChange={e => setValue(s.key, e.target.value)}
                      />
            )}
          </Grid>
          <Grid item xs={4} key={'settings_type'}>
            <Typography variant={"subtitle2"} style={{fontWeight: "bold"}}>{intl.formatMessage({
              id: "workflow.edit.dialog.information_heading",
              defaultMessage: "Information about the {type}"
            }, {type: locales[edit.item_type]})}</Typography>
            <Typography variant={"body2"}
                        dangerouslySetInnerHTML={{__html: (edit.item_type === "action" ? actions : events).find(i => i.id === edit.type)?.information}}/>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant={"outlined"}
                onClick={() => setEdit(null)}>{intl.formatMessage({id: "common.button.close"})}</Button>
        <Button variant={"contained"} color={"primary"}
                onClick={() => saveWorkflowItem()}>{intl.formatMessage({id: "common.button.save"})}</Button>
      </DialogActions>
    </Dialog>}
    {!!confirm && <ConfirmDialog
      onClose={deleteWorkflowItem}
      open={!!confirm}
      title={intl.formatMessage({
        id: "workflow.edit.confirm_item_delete.title",
        defaultMessage: "Confirm the deletion of {count} items"
      }, {
        count: findItemAndChildren(confirm).length
      })}
      message={intl.formatMessage({
        id: "workflow.edit.confirm_item_delete.message",
        defaultMessage: "Please confirm you want to delete the {type} and all the {count} items after it?"
      }, {
        type: locales[confirm.item_type],
        count: findItemAndChildren(confirm).length - 1,
      })}
    />}
  </Grid>;
}
