import React from "react";
import PropTypes from "prop-types";
import {Grid, Paper, Table, TableBody, TableCell, TableRow, Typography} from "@material-ui/core";
import EntityField from "./FieldTypes/EntityField";
import VariantField from "./FieldTypes/VariantField";
import {RenderFieldOfType} from "./FieldTypes/Field";
import EntitiesField from "./FieldTypes/EntitiesField";
import Box from "@material-ui/core/Box";
import RelatedField from "./FieldTypes/RelatedField";
import theme from "../../../assets/theme/theme";

function TabPanel(props) {
  const {children, value, index, ...other} = props;

  return (
    <Box
      key={'tabpanel-' + index}
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >{children}</Box>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

export default function LayoutTab(
  {
    tabIndex,
    tabSelected,
    fields,
    layout,
    entityTypes,
  }) {
  // const {language} = useContext(LanguageContext);

  const renderNode = (nodeId) => {
    let node = layout[nodeId];
    if (!node) return null;

    const renderFunctions = {
      Table: () => {
        let {columns, rows, width, align, background, spacing} = node.props;
        const c = [];
        for (let i = 0; i < columns; i++) {
          c.push(i + 1);
        }
        const r = [];
        for (let i = 0; i < rows; i++) {
          r.push(i + 1);
        }
        return <Table
          key={'table-' + nodeId}
          align={align}
          style={{
            margin: 0,
            width: width ? width + "%" : "auto",
            borderSpacing: spacing,
            borderCollapse: "separate",
            backgroundColor: background,
            borderRadius: 4,
          }}>
          <TableBody>{r.map(ri =>
            <TableRow key={'table-' + nodeId + '-row-' + ri}>{c.map(ci =>
              renderNode(node.linkedNodes["table-" + nodeId + "-row-" + ri + "-cell-" + ci])
            )}</TableRow>)}
          </TableBody>
        </Table>
      },
      Cell: () => {
        let {align, width, padding, background} = node.props;
        let justify = align === "left" ? "flex-start" : align === "right" ? "flex-end" : "center";
        return <TableCell key={'cell-' + nodeId} style={{
          width: width ? width + '%' : 'auto',
          minHeight: 16,
          margin: 0,
          padding: padding,
          textAlign: align,
          justifyContent: justify,
          backgroundColor: background,
          verticalAlign: "top",
        }}><Box style={{
          display: "flex",
          gap: 4,
          flexWrap: "wrap",
        }}>{node.nodes.map(n =>
          renderNode(n)
        )}</Box></TableCell>;
      },
      Fieldset: () => {
        let {title, background, padding} = node.props;
        return <Box
          key={'box-' + nodeId}
          component="fieldset"
          style={{
            borderRadius: 4,
            borderColor: theme.palette.divider,
            backgroundColor: background,
            padding: padding,
            minHeight: 16,
            width: "100%",
          }}>
          <legend
            style={{
              border: "1px solid " + theme.palette.divider,
              borderRadius: 4,
              backgroundColor: theme.palette.background.paper,
              padding: "2px 6px",
            }}
          >{title}</legend>
          <Grid container
                style={{
                  backgroundColor: background,
                  margin: 0,
                }}>{layout[node.linkedNodes.fieldset].nodes.map(n => renderNode(n))}</Grid>
        </Box>;
      },
      Block: () => {
        let {size, background, padding, align} = node.props;
        let justify = align === "left" ? "flex-start" : align === "right" ? "flex-end" : "center";
        let widthOffset = [0,
          7.35, // 1
          6.725,// 2
          6,  // 3
          5.4,  // 4
          5,  // 5
          4,  // 6
          3,  // 7
          3,  // 8
          2,  // 9
          1.75, // 10
          0.75, // 11
          0, // 12
        ];
        return <Box
          key={'box-' + nodeId}
          style={{
            borderRadius: 4,
            backgroundColor: background,
            padding: padding,
            textAlign: align,
            justifyContent: justify,
            minHeight: 16,
            display: "flex",
            flexDirection: "column",
            gap: 8,
            width: 'calc(' + (100 * size / 12) + '% - ' + widthOffset[size] + 'px)',
          }}>{
          layout[node.linkedNodes.block].nodes.map(n => renderNode(n))
        }</Box>;
      },
      Variant: () => {
        return <VariantField key={nodeId} nodeId={nodeId} node={node} fields={fields} entityTypes={entityTypes}/>;
      },
      Field: () => {
        let field = fields[node.props.field.id];
        let props = {
          key: nodeId,
          field: field,
          fields: fields,
          entityTypes: entityTypes,
        }
        // console.log(field.name, props, data);
        if (field?.type.search(/^relations_\d+$/) !== -1) {
          return <RelatedField {...props}/>;
        } else if (field?.type.search(/^entity_\d+$/) !== -1) {
          return <EntityField {...props}/>;
        } else if (field?.type.search(/^entities_\d+$/) !== -1) {
          return <EntitiesField {...props}/>;
        } else {
          return <RenderFieldOfType {...props}/>;
        }
      },
      Paragraph: () => {
        let {text, fontSize, style, weight, decoration, color} = node.props;
        return <Typography
          key={nodeId}
          style={{
            display: "block",
            color: color,
            fontSize: fontSize + 'px',
            textDecoration: decoration,
            fontWeight: weight,
            fontStyle: style,
            lineHeight: 1.1,
            margin: "10px 0",
          }}
        >{text.split('\n').map((t, i) => <span key={'text-' + i}>{t}<br/></span>)}</Typography>;
      },
      Text: () => {
        let {text, fontSize, style, weight, decoration, color} = node.props;
        return <Typography
          key={nodeId}
          style={{
            display: "inline",
            color: color,
            fontSize: fontSize + 'px',
            textDecoration: decoration,
            fontWeight: weight,
            fontStyle: style,
          }}
        >{text}</Typography>;
      },
      Asset: () => {
        let {asset, width} = node.props;
        return <img
          key={nodeId}
          src={asset}
          alt={asset?.split('/').pop()}
          title={asset?.split('/').pop()}
          style={{
            display: "inline",
            width: width + '%',
          }}
        />
      },
    }

    let type = node.displayName;
    if (!renderFunctions[type]) {
      type = node.type?.resolvedName;
    }
    // console.log(nodeId, type, node);
    if (!renderFunctions[type]) {
      return null;
    }
    return renderFunctions[type](node, nodeId);
  }
  // if (tabIndex === tabSelected) console.log(layout, layout.ROOT?.linkedNodes?.Layout, layout[layout.ROOT?.linkedNodes?.Layout]?.nodes);
  const main = layout[layout.ROOT?.linkedNodes?.Layout];
  return <TabPanel index={tabIndex} value={tabSelected}>
    {main && tabIndex === tabSelected && <Paper style={{...main.props.style, padding: 12, gap: 8, paddingRight: -8,}}>{
      main?.nodes.map(nodeId => renderNode(nodeId))
    }</Paper>}
  </TabPanel>
}
